import React, { useState, useCallback, useRef } from "react"
import { Link } from "gatsby"

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"


function Footer(props) {
  const { siteNav, dealerInfo, footer, footerClassName, allDealers } = props

  const observer = useRef()
  const [renderLazyItem, toggleLazyItem] = useState(false)
  const lazyItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        toggleLazyItem(true)
      }
    });
    if (node)
      observer.current.observe(node);
  })


  const renderDealerships = (dealers) => {
    if (dealers.length > 0) {
      return (<div className={`${view["dealerships"]}`}>
        <p className={`${view["dealerships-header"]}`}>Dealerships</p>
        <ul className={`${view["dealerships-list"]}`}>
          {
            dealers.map((item, idx) => (
              <li className={`${view["dealerships-list-item"]}`}>
                <Link id='nav_link' key={idx} className={`${view["dealerships-link"]}`} to={item.websiteLink != '' ? item.websiteLink : '/'}>
                  <span>{item.dealerName}</span>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>)
    }
    else {
      return (<span></span>)
    }
  }

  const renderSublinks = (sublinks) => (
    <ul className={view["foot-sublinks"]}>
      {
        sublinks.map((item, idx) => (
          <li>
            <Link id='nav_link' key={idx} className={`${view["btn-text-sublink"]}`} to={item.linkValue}>
              <span>{item.linkName}</span>
            </Link>
          </li>))
      }
    </ul>
  )

  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length <= 0)
            return (
              <li className={`${view["col-1"]}`}>
                <Link id='nav_link' key={idx} className={`${view["btn-text"]}`}
                  to={item.linkValue}>
                  <span>{item.linkName}</span>
                </Link>
              </li>
            )
        })
      }
    </div>
  )

  const renderLinkColumns = () => (
    <ul className={view["foot-link-header"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length > 0)
            return (
              <li className={`${view["solo-col"]}`}>
                <Link id='nav_link' key={idx} className={`${view["btn-text"]}`}
                  to={item.linkValue}>
                  <span>{item.linkName}</span>
                </Link>
                {item.subLinks.length > 0 ? renderSublinks(item.subLinks) : ''}
              </li>
            )
        })
      }
    </ul>
  )

  const renderBottomLinks = () => (
    <>
      {
        footer.bottomLinks.map((item, idx) => {
          return (
            <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
          )
        })

      }
    </>
  )

    const usableLink = (link) => {
        const notDefined = (link == undefined)
        const emptyString = !notDefined && link.replace(" ","") === ""
        return !(notDefined || emptyString)
    }

    const renderSocialColumn = () => {
      if(usableLink(footer.linkFB) || usableLink(footer.linkInsta) || usableLink(footer.linkTwit) || usableLink(footer.linkYelp) || usableLink(footer.linkYT) || usableLink(footer.linkLI)) 
      return(
      <div className={view["social-cont"]}>
        <h3>Connect With Us</h3>
        <div className={view['social-icon-list']}>
         {usableLink(footer.linkFB) ? <Link id='nav_link' to={footer.linkFB} alt="Our Facebook" className={`${view["social-icon"]} ${view["fb"]}`}></Link> : <span></span>}
         {usableLink(footer.linkInsta)  ? <Link id='nav_link' to={footer.linkInsta} alt="Our Instagram" className={`${view["social-icon"]} ${view["insta"]}`}></Link> : <span></span>}
         {usableLink(footer.linkTwit) ? <Link id='nav_link' to={footer.linkTwit} alt="Our Twitter" className={`${view["social-icon"]} ${view["twitter"]}`}></Link> : <span></span>}
         {usableLink(footer.linkYelp) ? <Link id='nav_link' to={footer.linkYelp} alt="Our Yelp" className={`${view["social-icon"]} ${view["yelp"]}`}></Link> : <span></span>}
         {usableLink(footer.linkYT) ? <Link id='nav_link' to={footer.linkYT} alt="Our YouTube" className={`${view["social-icon"]} ${view["youtube"]}`}></Link> : <span></span>}
         {usableLink(footer.linkLI) ? <Link id='nav_link' to={footer.linkLI} alt="Our LinkedIn" className={`${view["social-icon"]} ${view["linked"]}`}></Link> : <span></span>}
         </div>
      </div>
      )}
  
    const renderAppColumn = () =>  {
      if(usableLink(footer.linkIOS) || usableLink(footer.linkAndroid)) 
      return (
      <div className={view["app-cont"]}>
        {  <h3>Download Our App</h3>}
        { usableLink(footer.linkIOS) ? <Link id='nav_link' to={footer.linkIOS} alt="Our IOS App" className={`${view["iosBtn"]} ${view["ios"]}`}></Link> : <span></span>}
        { usableLink(footer.linkAndroid) ? <Link id='nav_link' to={footer.linkAndroid} alt="Our Android App" className={`${view["AndroidBtn"]} ${view["android"]}`}></Link> : <span></span>}
      </div>
    )}
  
  const loadFooterImage = () => {
    return typeof window !== 'undefined' && (
      <div className={view["logo-wrap"]}>
        <img className={view["foot-img"]} src={footer.logo} alt="Honda Dealers Logo" title="Honda Dealers Logo"/>
      </div>
    )
  }

  return (
    <>
      <footer id="footer" ref={lazyItem} className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
        <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
          <div className={`${view["dealer-info"]}`}>
            {renderDealerships(allDealers)}
            <div className={view["call-block"]}>
              {/*<h3>Call us on</h3>*/}
              {/*<p>Sales <span>{dealerInfo.salesNumber}</span></p>*/}
              {/*<p>Service <span>{dealerInfo.serviceNumber}</span></p>*/}
            </div>
          </div>
          <div className={`${view["link-block"]} ${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
            {renderLazyItem && loadFooterImage()}
            {renderLinks()}
            {renderLinkColumns()}
            {renderSocialColumn()}
            {renderAppColumn()}
          </div>
          {/*<button className={view["scrollTop"]} onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Top <span className={view["dash"]}></span></button>*/}
        </div>
      </footer>

      <div className={`${view["subtitle-box"]} ${grid["col-lg-12"]}`} style={{ textAlign: "center" }}>
        <p className={` ${view["subtitle"]} ${view["last"]}`} style={{ lineHeight: "1" }}>Copyright &#169; {dealerInfo.dealerName} all rights reserved</p>
        <div className={view["bottom-links"]}>
          {renderBottomLinks()}
        </div>
        <div className={view["dm-box"]} style={{ display: "flex", flexDirection: "column" }}>

          {<CookiePolicy disclaimer={footer.cookieDisclaimer} dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />}
          <Link id='nav_link' to={"https://dealermasters.com/"} className={`${view["last"]} ${view["DM-spot"]}`}>
            <p style={{ display: "inline-block" }}>Made with</p>
            <span className={view["heart"]} aria-label={"heart"} role={"img"}> &#10084; ️</span>
            <p style={{ display: "inline-block" }}>by Dealer Masters</p>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Footer
