import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby";
import { useLocation } from '@reach/router';

import { buildOnPageMetadata } from "event-service/modules/utility";
import { AddASCClickableHandlers, AddASCNavHandlers, exposeCaptureEvent, processStoredEvents, captureEvent, AddASCClickToCallHandlers } from "event-service/modules/capture";

import TopNavigation from "../components/navigation/TopNavigation"
import Footer from '../components/footer/Footer'
import SEO  from './SEO'
import DealerRibbon from "../components/dealerRibbon/dealerRibbon"

import useModal from "../hooks/useModal";
import AvailabilityForm from "./modal/content/AvailabilityForm";

import "../scss/font-definitions.scss"
import '../scss/php-scss.scss'
import '../scss/layout.scss'
import LayoutHTML from '../components/LayoutHTML'
import GTMTracker from "./gtmTracker/GTMTracker";
import {localStorage, getStorageItem, setStorageItem, removeStorageItem} from '../components/localStorage/localStorage.js'
import  FindDealerProvider  from "../components/findDealer/context/findDealerContext"
import {navigate} from "../../.cache/gatsby-browser-entry";
import BottomMessage from "./bottomMessage/BottomMessage";
import axios from 'axios'

import useDataLayer from '../hooks/useDataLayer'

const { siteMetadata } = require('../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;
const distance_history = siteMetadata.distance_history;



export default function Layout({ children, seo,footerClassName }) {
  let ModalTitle = ""
  const pushToDataLayer = useDataLayer()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  for (const [key, value] of params) {
      if(key === "title"){
          ModalTitle = value
      }

  }
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded) {
      exposeCaptureEvent();
      processStoredEvents();
      AddASCNavHandlers();
      AddASCClickableHandlers();
      AddASCClickToCallHandlers();
      captureEvent({
        event: 'asc_pageview',
        error_code: '',
        item_id: document.querySelector('data[id="vin"]')?.value || '',
        item_number: document.querySelector('data[id="stock"]')?.value || '',
        item_price: document.querySelector('data[id="price"]')?.value || '',
        item_condition: document.querySelector('data[id="condition"]')?.value || '',
        item_year: document.querySelector('data[id="year"]')?.value || '',
        item_make: document.querySelector('data[id="make"]')?.value || '',
        item_model: document.querySelector('data[id="model"]')?.value || '',
        item_variant: document.querySelector('data[id="trim"]')?.value || '',
        item_color: document.querySelector('data[id="color"]')?.value || '',
        item_type: document.querySelector('data[id="type"]')?.value || '',
        item_category: document.querySelector('data[id="category"]')?.value || '',
        item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
        item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
        item_results: document.querySelector('data[id="results"]')?.value || '',
      }, {
        handlers: ['ASC']
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
          console.log(error);
      });
    }
  }, [loaded])
  const [renderModal] = useModal({
    title: ModalTitle,
    location: "#contact-form"
})
  return <StaticQuery
    query={pagesQuery}
    render={data => {
      const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
      if (!loaded) {
        setLoaded(siteSettings.dealerInfo)
        if (siteSettings.dealerFeedInfo) {
            buildOnPageMetadata({
                postalCode: siteSettings.dealerFeedInfo?.postalCode,
                state: siteSettings.dealerFeedInfo?.state,
                city: siteSettings.dealerFeedInfo?.city
            })
        }
      }
      const siteNav = JSON.parse(data.siteData.siteData.siteNav)
      const { theme } = siteSettings
      const html = siteSettings.html
      
      const site_id = siteMetadata.site_id

      const dealerLocals = siteSettings.dealers

      let primaryFont = '"Nunito", sans-serif'
      let secondaryFont = '"Nunito", sans-serif'
      let tertiaryFont = '"Nunito, sans-serif'

      const rgbaTranslator = (hexCode, opacityValue) => {
        const rVal = parseInt(hexCode.substr(1,2), 16).toString()
        const gVal = parseInt(hexCode.substr(3,2), 16).toString()
        const bVal = parseInt(hexCode.substr(5,2), 16).toString()
        if(rVal == NaN || gVal == NaN || bVal == NaN){
          return hexCode
      }
        return "rgba(" + rVal +  ", " + gVal + ", " + bVal + ", " + opacityValue + ")"
      }

      const document = typeof window !== 'undefined' && window.document

      const modalContactContent = (
        <AvailabilityForm autofillable leadURL={siteMetadata.lead_url}
          gtmId={siteSettings.dealerInfo.gtmId}
          pageURL={document.URL}
          pageSource='Contact'
          site_id={siteMetadata.site_id}
          dealers={siteSettings.dealers}
          pageLoc="General"
        />
      )

      const themeStyle = {
        primary: theme.colors.primary,
        secondary: theme.colors.secondary,
        primaryText: theme.colors.text,
        secondaryText: theme.colors.secondarytext,
        primaryAccent: theme.colors.accent1,
        secondaryAccent: theme.colors.accent2,
        primaryFont: primaryFont,
        secondaryFont: secondaryFont,
        tertiaryFont: tertiaryFont,
        primaryTranslucent: rgbaTranslator(theme.colors.primary, '0.5')
    }
    

    function formSubmit(contactElements, optionalFields, responseField) {

      let data = {}
      data.call = ''
      data.text = ''
      data.checkEmail = ''
      data.firstName = contactElements.firstName
      data.lastName = contactElements.lastName
      data.email = contactElements.email
      data.phone = contactElements.phone
      data.comments = contactElements.comments
      data.siteId = siteMetadata.site_id
      data.pageSource = contactElements.pageSource
      data.pageURL = window.location.href

      let optionalData = '\n Additional information -'
      optionalFields.forEach(element => {
          optionalData += ' ' + element
      })
      data.comments += (optionalData != '\n Additional information -') ? optionalData : ''

      axios.post(siteMetadata.lead_url, JSON.stringify(data))
          .then(res => {
              pushToDataLayer("form-submission", res)
              pushToDataLayer("contact", res)

              responseField.innerText = 'Success! The message has been sent!'
              responseField.classList = 'contact-success'
          })
          .catch(err => {
              console.log(err)

              responseField.innerText = 'Uh oh! Something went wrong, please try again!'
              responseField.classList = 'contact-failure'
          })
  }

    const getDealers = () => {
      return dealerLocals
    } 
    
    if (typeof window !== 'undefined'){
      window.gatsbyNavigate = navigate;
      window.SiteQuickgenId = siteSettings.dealerInfo.quickgenID;
      window.siteId = site_id;
      window.getDealers = getDealers;
      window.customFormSubmit = formSubmit;

    }

    return (
        <React.Fragment>

          { (seo !== undefined) &&<SEO key={"SEO"} title={"Layout"} data={seo}/> }
          <style type="text/css">
            {
              `:root {
              --primary-color: ${themeStyle.primary};
              --secondary-color: ${themeStyle.secondary};
              --primary-text:  ${themeStyle.primaryText};
              --secondary-text: ${themeStyle.secondaryText};
              --primary-accent: ${themeStyle.primaryAccent};
              --secondary-accent: ${themeStyle.secondaryAccent};
              --primary-font: ${themeStyle.primaryFont};
              --secondary-font: ${themeStyle.secondaryFont};
              --tertiary-font: ${themeStyle.tertiaryFont};
              --primary-translucent: ${themeStyle.primaryTranslucent};
            }`
            }
          </style>
          <FindDealerProvider dealerInfo={siteSettings.dealerInfo } dealerLocals={dealerLocals} distancHistoryUrl={distance_history} googleAPIkey={googleAPIkey}>

          <GTMTracker gtmId={siteSettings.dealerInfo.gtmId}/>

          {/* <DealerRibbon data={siteSettings.dealerInfo} /> */}
          <TopNavigation siteNav={siteNav}
            dealerInfo={siteSettings.dealerInfo} />
          <LayoutHTML data={html.topHtml} />
          { renderModal(modalContactContent) }
          <div className="body">
            {children}
          </div>

            <LayoutHTML data={html.bottomHtml} />
              <BottomMessage></BottomMessage>
            <Footer footerClassName={footerClassName} siteNav={siteNav} footer={siteSettings.Footer} dealerInfo={siteSettings.dealerInfo} allDealers={dealerLocals.dealerRef} />
          </FindDealerProvider>
        </React.Fragment>
      )
    }}
  />
}

const pagesQuery = graphql`
{
  siteData {
      siteData {
      siteSettings
      siteNav
      }
  }
}
`
