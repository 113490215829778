import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { getStorageItem,setStorageItem } from '../../../Modules/localStorage'
const { siteMetadata } = require('../../../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;


export const FindDealerContext = React.createContext();

const FindDealerProvider = (props) => {
  const { site_id } = siteMetadata;
  //Defined initial states
  const [zipcode, setZipcode] = useState('');
  const [dealerAddresses, setDealerAddresses] = useState(props);
  const [localStorageDistanceData, setLocalStorageDistanceData] = useState();
  const [locationsList, setLocationsList] = useState(getStorageItem(`localDistanceData${site_id}`));
  const dealerInformation = props.dealerLocals;
  const dataInLocalStorage = getStorageItem(`localDistanceData${site_id}`);
  const [loadingList, setLoadingList] = useState();
  const [closestDealerCompPresent, setClosestDealerCompPresent] = useState(false);
  const distanceHistoryUrl =  props.distancHistoryUrl;
  const distanceRef = useRef();
  const scrollToListRef = useRef(null);

  const openBottomMessage = typeof window !== 'undefined' && window.openBottomMessage

  //Get the current search param from window.location, parse the params from the url and get any value from searchZip in query string. If available, send it to searchZipCode to be processed for search
  useEffect(()=>{

    const convertQS = window.location.search;

    let params = new URLSearchParams(convertQS);

    let q = params.get("searchedZip");

    if(q !== null){searchZipCode(q);}
  
  },[]);
  
  //Decide what to put into localStorage on inital visit
  useEffect(()=>{
      
    //For loading purposes
    setLoadingList(true);

    //Get whatever is in localStorage for the distance data
    const dealerDistance = getStorageItem(`localDistanceData${site_id}`);
    const dealerDistanceObj = JSON.parse(dealerDistance);

    //If localStorage is null, no data has been saved yet so grab it from the props DealerRef
    if(dealerDistance === null){
      console.log('data not found in local')

      //Reformat the data to make it universal for the DealerList
      const storageDealerData = [];
        
      for(let i = 0; i < dealerInformation.dealerRef.length; i++){

        storageDealerData.push({
          
            "dealerName" : dealerInformation.dealerRef[i].dealerName,
            "dealerAddress" : dealerInformation.dealerRef[i].dealerAddress,
            "websiteLink" : dealerInformation.dealerRef[i].websiteLink,
            "dealerPhoneNumber" : dealerInformation.dealerRef[i].mainPhoneNumber,
            "distances" : dealerInformation.dealerRef[i].distances,
            "inventoryURL" : dealerInformation.dealerRef[i].inventoryURL ,
            "offersPageURL" : dealerInformation.dealerRef[i].offersPageURL ,
            "dealerImage" : dealerInformation.dealerRef[i].dealerImage
        })

      }

      //Make sure we are passing site_id
      const initialStorage =  {
        "site_id":site_id,
        "closest_dealers":storageDealerData
      }

      //Save the new reformatted data in closest_dealers object with the site_id in localStorage
      setStorageItem(`localDistanceData${site_id}`, JSON.stringify(initialStorage));

      //Store current dealer list hashed in localStorage
      setStorageItem(`hashList${site_id}`, JSON.stringify(dealerInformation.dealerRef));

      
      //Hide loading and show list
      setLoadingList(false);
      
      return;
      
    }

    //Data in localStorage if one of the API requests stored it either from DistanceMatrix or from Lambda functions
    const dataFromLocalStorage = (dealerDistanceObj.closest_dealers !== 'undefined' && dealerDistanceObj.closest_dealers);
    // const zipFromLocalStorage = (dealerDistanceObj.closest_dealers !== 'undefined' && dealerDistanceObj.closest_dealers);
    const hashList = (localStorage !== 'undefined' &&  localStorage.getItem(`hashList${site_id}`));
    const propList = JSON.stringify(dealerInformation.dealerRef);
    
    if(dataFromLocalStorage){
      if (propList !== hashList && !dealerDistanceObj.searched_zip) {
        dealerDistanceObj.closest_dealers = dealerInformation.dealerRef

        setStorageItem(`localDistanceData${site_id}`, JSON.stringify(dealerDistanceObj));
        setStorageItem(`hashList${site_id}`, JSON.stringify(dealerInformation.dealerRef));
        setLoadingList(false);
        return 
      }

      if (propList !== hashList && dealerDistanceObj.searched_zip) {
        setStorageItem(`hashList${site_id}`, JSON.stringify(dealerInformation.dealerRef));
        isThereHistory(dealerDistanceObj.searched_zip)
        return
      }

    }

    //For loading purposes
    setLoadingList(false);

  },[dealerInformation]);


  /**
   * Set Reference's current item based off of whatever is in the current localStorage data
   */
  const initDistanceRef = () => {
    
    distanceRef.current = {...distanceRef.current, "newItem" : dataInLocalStorage }

  }

  //Needs to be invoked freely to always catch the latest data in localStorage otherwise distanceRef gets stale
  initDistanceRef();
  
  //Parsed data from the distanceRef since it's a string in localStorage
  const updatedDataRef = JSON.parse(distanceRef.current.newItem );

  //If the reference is loaded it's safe to display list, set the state of loading to false
  useEffect(()=>{

    if(updatedDataRef != null){

      setLoadingList(false);

    }

  },[updatedDataRef]);

  /**
   * Method that takes the whatever the searched zipcode is and saves it into the url query string params
   * 
   * @param {string} zip zip code searched
   */
  function saveQueryStringParam(zip){

    //Grab current location URL
    const convertQS = window.location;
    
    //New URL to get the searchParams
    const url = new URL(convertQS);
    
    //Define searchParams
    const params = url.searchParams;

    //Define object to pass into query string
    const state = { searchedZip: zip };

    //Title should always be empty for now, browsers do not recognize it currentlty but will in the future
    const title = '';

    //Set the params to save the searchedZip object within location.search
    params.set('searchedZip', zip);
    

    //Push to history state and update URL value to the value that is in the location.search
    if(closestDealerCompPresent === true){
      window.history.pushState(state, title, url.search);
      return;
    } 
  }

 /**
   * Method that takes the whatever the searched zipcode is and saves it into the url query string params
   * 
   * @param {string} zip zip code searched
   */
  function saveQueryStringLinkTo(zip){

    //Grab current location URL
    const convertQS = window.location;
    
    //New URL to get the searchParams
    const url = new URL(convertQS);
    
    //Define searchParams
    const params = url.searchParams;

    //Define object to pass into query string
    const state = { searchedZip: zip };

    //Title should always be empty for now, browsers do not recognize it currentlty but will in the future
    const title = '';

    url.pathname = '/dealer-locator/'

    //New URL to get the searchParams
    // const url = new URL(convertQS);

    //Set the params to save the searchedZip object within location.search
    params.set('searchedZip', zip);
    
    // const finalUrl =`url.search`;
    const finalUrl =`/dealer-locator/${url.search}`;

    // finalUrl.toString();
    
    navigate(finalUrl, { replace: false });

  }

  const isThereHistory = (zip) => {

    const checkParams = {
      siteID : site_id,
      searchedZip : zip,
      dealerRef: dealerInformation.dealerRef
    }
    const stringParams = JSON.stringify(checkParams)
    try { 
      
      axios.post(`${distanceHistoryUrl}/getDistanceHistory`,{params:stringParams}).then(res => {
        setStorageItem(`localDistanceData${site_id}`, JSON.stringify(res.data));
        setLocalStorageDistanceData(res.data);   
        setLoadingList(false);

      }).catch((error) => {
        console.warn('Not good :(', error);
      })
     
    } catch (e) { console.log(e) }

  }


  function scrollToListElement(){
    scrollToListRef.current.scrollIntoView(false);
  }
  
  /** 
   * Calls multiple functions from context using the zip code entered in the form.
   * @param {string} formZip zip code entered in search form.
   */

  function correctZipFormat(zip){
    String.prototype.isNumber = function(){return /^\d+$/.test(this);}
    return (zip != "") && (zip.length == 5) && zip.isNumber();
  }

  function searchZipCode(formZip){
    if(correctZipFormat(formZip)) {
      setLoadingList(true);
      if (closestDealerCompPresent === true) {
        saveQueryStringParam(formZip);
      }
      if (closestDealerCompPresent === false) {
        saveQueryStringLinkTo(formZip);
      }
      setZipcode(formZip);
      isThereHistory(formZip);
    }else {
      openBottomMessage("Find a Dealer", "Incorrect Zip Code Format")
    }
  }

  const getDirections = (dealerAdd) => {
    const baseURL = "https://www.google.com/maps/dir/";
    const escapedOrigin = String(zipcode).replaceAll(' ', '+');
    const destiny = String(dealerAdd).replaceAll(' ', '+');
    window.open(`${baseURL}${escapedOrigin}/${destiny}`, '_blank').focus();
  }


  //Props to send through context for sharing between components
  const findDealerProps = { zipcode, setZipcode, site_id, dealerAddresses, setDealerAddresses, isThereHistory, localStorageDistanceData, locationsList, setLocationsList, distanceRef, loadingList, setLoadingList, saveQueryStringParam, searchZipCode, closestDealerCompPresent, setClosestDealerCompPresent, getDirections, scrollToListRef, scrollToListElement};

  return (
    <FindDealerContext.Provider value={findDealerProps}>
      {props.children}
    </FindDealerContext.Provider>
  );
}

export default FindDealerProvider;