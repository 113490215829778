import React, { useState } from 'react'
import { Link } from 'gatsby'

import FindDealerNav from '../findDealer/findDealerNav'
import view from '../findDealer/findDealer.module.scss'

import "../../scss/NavigationBar.scss"
import style from "./navigation.module.scss"

const { siteMetadata } = require('../../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;

function NavigationBar(props) {
  const { siteNav, dealerLocals, dealerInfo } = props;

  const [showMobile, toggleMobile] = useState(false)

  const renderLinkElement = ({ linkName, linkPath }) => (
    <Link id='nav_link' to={linkPath} className={style["main-menu"]}>
      {linkName}
    </Link>
  )

  const renderMenuElement = (menuItems, isChild) => (
    <ul className={isChild ? style["child-menu"] : style["main-menu"]}>
      {menuItems.map((item, i) => {
        const hasChildren = item.link.children?.length > 0 || item.children?.length > 0
        const children = item.link.children || item.children
        return (
          <li key={i} className={isChild ? style["children"] : style["element"]}>
            {
              hasChildren ?
                <>
                  <a className={hasChildren && style["dropdown"]}>
                    {item.link.linkName}
                  </a>
                  {renderMenuElement(children, hasChildren)}
                </> :
                renderLinkElement(item.link)
            }
          </li>
        )
      })}
    </ul>
  )

  const renderMenuMobile = (menuItems, isChild,showID) => (
    <ul id={showID} className={isChild ? style["resp-menu"] : style["resp-main"]}>
      {menuItems.map((item, i) => {
        const hasChildren = item.link.children?.length > 0 || item.children?.length > 0
        const children = item.link.children || item.children
        const showID = item.link.linkName +"-" + i + '-mobile'


        /*const document = typeof window !== 'undefined' && window.document
        const elements = document.getElementsByClassName("mobileLink");
        const myFunction = function() {
          toggleMobile(!showMobile)
        };

        Array.from(elements).forEach(function(element) {
          element.addEventListener('click', myFunction);
        });*/

        return (
            <li key={i} className={isChild ? style["resp-child"] + ' ' + ["mobileLink"] : style["resp-elem"]}>
              {
              hasChildren ?
                <>
                  <a tabindex={-1} className={hasChildren && style["resp-drop"]} onClick={() => toggleDrop(showID)}>
                    {item.link.linkName}
                  </a>
                  {renderMenuMobile(children, hasChildren,showID)}
                </> :
                renderLinkElement(item.link)
            }
          </li>
        )
      })}
    </ul>
  )

  return (
    <header id="header">
      <div className={style["header"]}>
        <section className={`header__section site__subsection ${style["logo-cont"]}`}>
          {String(props.dealerInfo.dealerLogo).length > 0 &&
          <Link id='nav_link' className={style["nav-logo"] + " " + style["nav-logo-primary"]} to="/">
            <img className={style["nav-icon"]} src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName}/>
          </Link>
          }
          {String(props.dealerInfo.groupLogo).length > 0 &&
            <Link id='nav_link' className={style["nav-logo"]} to="/">
              <img className={style["nav-icon"]} src={props.dealerInfo.groupLogo} alt={props.dealerInfo.dealerName} />
            </Link>
          }
        </section>
        <menu className={style["menu-wrap"]}>
          {renderMenuElement(props.siteNav.menu)}
        </menu>
        <a onClick={() => toggleMobile(!showMobile)} class="burger header__explore-menu js-header-explore-button">
          <span className={style["menu-icon"]} alt="hamburger icon for expandable menu">&#9776;</span>
          <span>MENU</span>
        </a>
        <section id="find-dealer-nav" className={`header__section site__subsection ${view["nav-search"]}`}>
          <FindDealerNav dealerInfo={{ ...dealerInfo }} dealerLocals={dealerLocals} googleAPIkey={googleAPIkey} />
        </section>
      </div>
      <section id="mobileDropDown" className={`nav-cont ${style["nav-mobile"]} ${showMobile && style["mobile-menu"]}`}>
        {renderMenuMobile(props.siteNav.menu)}
      </section>
    </header>
  )
}

function toggleDrop(id) {
  const div = document.getElementById(id)
  if(div.style.display == "" || div.style.display == "none"){
    div.style.display = "block"
  }else {
    div.style.display = "none"
  }
}

function toggleMobile() {
  document.getElementById("mobileDropDown").style.display = "block"
}

export default NavigationBar

