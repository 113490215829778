const siteUrl = 'https://www.hartautogroup.com/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Nissan Tier 2',
    siteUrl:'https://www.hartautogroup.com/',
    site_id: '62f556cb10c6d3e9abe42c41',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',    
    distance_history: 'https://aalnfl2zj7.execute-api.us-east-2.amazonaws.com/Prod', //Distance history API Gateway AWS
    googleAPIkey: 'AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY',
    quickgenAPI: 'https://dev.mxsapi.com',
    imageBaseURL: 'https://images.mxssolutions.com', //PROD: 'https://images.mxssolutions.com'
    InventoryEnabled:'',
    master_sites: []
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: "gatsby-plugin-force-trailing-slashes"
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-649783c2-26b2-412a-8160-ea06cc03e6c0-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['216740'],
        makes: [],
        InventoryEnabled:''
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.hartautogroup.com/',
        sitemap: 'https://www.hartautogroup.com//sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}